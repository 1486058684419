
@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/GOTHAMBLACK.OTF') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/GOTHAMBOLD.TTF') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/GOTHAMBOLDITALIC.TTF') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/GOTHAMBOOK.TTF') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/GOTHAMLIGHTITALIC.TTF') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/GOTHAMLIGHT.TTF') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/GOTHAMLIGHTITALIC.TTF') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/GOTHAMMEDIUM.TTF') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/GOTHAMTHIN.OTF') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/fonts/GOTHAMTHINITALIC.OTF') format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Neo Sans ";
  src: url("./assets/fonts/NEOSANSARABICBLACK.TTF") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Neo Sans ";
  src: url("./assets/fonts/NEOSANSARABICBOLD.TTF") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Neo Sans ";
  src: url("./assets/fonts/NEOSANSARABICMEDIUM.TTF") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Neo Sans ";
  src: url("./assets/fonts/NEOSANSARABICREGULAR.TTF") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Neo Sans ";
  src: url("./assets/fonts/NEOSANSARABICULTRA.TTF") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Neo Sans ";
  src: url("./assets/fonts/NEOSANSMEDIUM.TTF") format("truetype");
  font-weight: 500;
  font-style: normal;
}

body {
  margin: 0;
}
body[dir="ltr"] {
  font-family: "Gotham", sans-serif;
}

body[dir="rtl"] {
  font-family: "Neo Sans ", sans-serif;
}

.custom-dot {
  width: 20px;
  height: 1px;
  background-color: gray;
}

.slick-dots .slick-active .custom-dot {
  background-color: #07489d;
}
.fadeIn {
  animation: fadeIn 2s ease-in-out;
}

.moveText {
  animation: moveText 3s ease-in-out infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes moveText {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(
      -20px
    ); 
  }
  100% {
    transform: translateY(0);
  }
}

.slick-prev, .slick-next {
  z-index: 1;
  color: white;
}

.slick-prev:before,
.slick-next:before {
  font-size: 30px;
  color: white;
}

.slick-dots li button:before {
  font-size: 12px;
  color: gray;
}

.custom-dot {
  height: 8px;
  width: 25px;
  border-radius: 8px;
}
:root {
  --foreground: #171717;
}
img,
video {
  max-width: 100%;
  height: revert-layer;
}

.css-1jk99ih-MuiInputBase-input-MuiOutlinedInput-input {
  height: 12px;
  min-width: 250px;
}
.custom-toast {
  z-index: 11111 !important;
}
.responsive-image {
  display: block;
}

@media (max-width: 1024px) {

  .responsive-image {
    display: none;
  }
}
.offcanvas-header {
  background-color: #003366;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.custom-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: capitalize;

}

.custom-body {
  padding: 20px;
  background-color: #ffffff;
}
:dir(ltr) .custom-body {
  font-family: "Poppins", sans-serif !important;
}

:dir(rtl) .custom-body {
  font-family: "Neo Sans " !important;
}

.custom-btn-close {
  color: red !important; /* Change to your desired color */
  opacity: 1; /* Ensure the color is not affected by Bootstrap's default opacity */
}


.custom-close-icon {
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  background-color: transparent;
}
input[name="phone"] {
  text-align: left;
  direction: ltr;
}
